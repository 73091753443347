import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarUpload from './avatar';
import Thanks from './thanks';
import closeIcon from '../img/icons8-close-80.png'
import './style.css';

const Download = () => {
    const [telegram, setTelegram] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [link, setUrl] = useState('');
    const [image, setImage] = useState(null); // Здесь будет сохраняться выбранное изображение
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
  
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    };
    const maxLength = 450;

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'telegram':
                setTelegram(value);
                break;
            case 'company_name':
                setCompanyName(value);
                break;
            case 'category':
                setCategory(value);
                break;
            case 'description':
                setDescription(e.target.value);
                break;
            case 'link':
                setUrl(value);
                break;
            default:
                break;
        }
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };

    const handleImageChange = (file) => {
        setImage(file); // Сохраняем изображение в состоянии
    };

//    function getCookie(name) {
//        let cookieValue = null;
//        if (document.cookie && document.cookie !== '') {
//            const cookies = document.cookie.split(';');
//            for (let i = 0; i < cookies.length; i++) {
//                const cookie = cookies[i].trim();
//                if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                    break;
//                }
//            }
//        }
//        return cookieValue;
//    }



    const handleSubmit = (event) => {
        event.preventDefault();

        let formErrors = {};
        if (!telegram) {
            formErrors.telegram = 'Заполните ваш телеграм';
        }
        if (!company_name) {
            formErrors.company_name = 'Заполните название компании';
        }
        if (!category) {
            formErrors.category = 'Заполните категорию';
        }
        if (!description) {
            formErrors.description = 'Заполните описание';
        }
        if (!link) {
            formErrors.link = 'Заполните ссылку';
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const formData = new FormData();
        formData.append('telega', telegram);
        formData.append('company_name', company_name);
        formData.append('category', category);
        formData.append('description', description);
        formData.append('link', link);
        if (image) {
            formData.append('image', image); // Добавляем изображение в FormData
        }
//        const csrftoken = getCookie('csrftoken');

        fetch('/api/order/', {
            method: 'POST',
            body: formData,
            redirect: "follow",
            headers: {
                'Accept': '*/*',
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                setIsSubmitted(true);
            } else {
                setErrors(data.errors || {});
            }
        })
        .catch(error => {
            console.error('Ошибка при отправке формы:', error);
        });
    };

    return (
        <div className='wrapper mobile'>
            {isSubmitted ? (
                <Thanks />
            ) : (
                <>
                    {Object.values(errors).some(error => error) && (
                        <div className="form__block">
                            <div className="form__error">
                                <ul>
                                    {errors.telegram && (
                                        <li>
                                            <span className="form__error-text">{errors.telegram}</span>
                                        </li>
                                    )}
                                    {errors.companyName && (
                                        <li>
                                            <span className="form__error-text">{errors.companyName}</span>
                                        </li>
                                    )}
                                    {errors.category && (
                                        <li>
                                            <span className="form__error-text">{errors.category}</span>
                                        </li>
                                    )}
                                    {errors.description && (
                                        <li>
                                            <span className="form__error-text">{errors.description}</span>
                                        </li>
                                    )}
                                    {errors.url && (
                                        <li>
                                            <span className="form__error-text">{errors.url}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}

                    <div className="form__block">
                        <div className="form__close"><img onClick={handleClick} src={closeIcon} alt="" /></div>
                        <form onSubmit={handleSubmit}>
                            <h4 className='form__title'>Создать заявку на размещение</h4>
                            <div className='form__grup'>
                                <input
                                    placeholder=" "
                                    className='form__grup-input'
                                    type="text"
                                    id="telegram"
                                    name="telegram"
                                    value={telegram}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label' htmlFor="telegram">Ваш телеграм:</label>
                            </div>
                            <div className='form__grup'>
                                <input
                                    placeholder=" "
                                    className='form__grup-input'
                                    type="text"
                                    id="company_name"
                                    name="company_name"
                                    value={company_name}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label' htmlFor="companyName">Название компании:</label>
                            </div>
                            <div className='form__grup'>
                                <input
                                    placeholder=" "
                                    className='form__grup-input'
                                    type="text"
                                    id="category"
                                    name="category"
                                    value={category}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label' htmlFor="category">Категория (PSP, Телефония, Базы...):</label>
                            </div>
                            <div className='form__grup'>
                                <textarea
                                    placeholder=" "
                                    className='form__grup-textarea'
                                    id="description"
                                    name="description"
                                    value={description}
                                    maxLength={maxLength}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label__textarea' htmlFor="description">Описание / ссылки / сайт / УТП ({description.length} из {maxLength}):</label>
                            </div>
                            <div className='form__grup'>
                                <input
                                    placeholder=" "
                                    className='form__grup-input'
                                    type="text"
                                    id="link"
                                    name="link"
                                    value={link}
                                    onChange={handleChange}
                                />
                                <label className='form__grup-label' htmlFor="url">Ссылка:</label>
                            </div>
                            <div className="form__logo">
                                <div className="form__text">Загрузить лого</div>
                                <AvatarUpload onImageChange={handleImageChange} />
                            </div>
                            <button type="submit" className='form__btn'>Отправить на модерацию</button>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default Download;