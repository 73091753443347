import ItemsList from './components/itemsList'
import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Download from './components/download'
import Error from './components/error'
import Thanks from './components/thanks';

import { useNavigate } from 'react-router-dom';
import './App.css';
import { useParams } from 'react-router-dom';


function App() {
    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('language');
        return savedLanguage || 'ua';
    });
    const toggleLanguage = () => {
        setLanguage((prevLang) => {
            const newLang = prevLang === 'en' ? 'ua' : 'en';
            localStorage.setItem('language', newLang);
            return newLang;
        });
    };
    return (<Router>
        <Routes>
            <Route path="/" element={<ItemsMain language={language} toggleLanguage={toggleLanguage} />} />
            <Route path="/items/:id" element={<ItemsCategory language={language} />} />
            <Route path="/form" element={<Download />} />
            <Route path="/thank" element={<Thanks />} />
            <Route path="/error" element={<Error />} />
        </Routes>
    </Router>
    );
}

function ItemsMain({ language, toggleLanguage }) {

    const navigate = useNavigate();
    const handleService = () => { navigate('/form'); };
    const query = `/api/?l=${language}`

    return (
        <ItemsList main_list={true} handleService={handleService} query={query} toggleLanguage={toggleLanguage}  language={language}  />
    );
}

function ItemsCategory({ language }) {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleService = () => { navigate('/form'); };
    const query = `/api/item/?l=${language}&c=${id}`
    return (
        <ItemsList main_list={false} handleService={handleService} query={query} />
    );
}


export default App;
