import { useNavigate } from 'react-router-dom';
import RunningText from './runningText';
import backIcon from '../img/back.png'
import logo from '../img/logo.png'

import './style.css'


const HeaderPrev = ({ text_button, text_run }) => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(-1);
    };

    return <>
        <div className="header">
            <div className="header__top">
            <div className="logo"><img src={logo} alt="" /></div>
            <div className="header__lang" onClick={handleClick}> <img className='header__lang-back' src={backIcon} alt="" /> </div>

            </div>
            <div className="header__marquee-container">
                <div className="header__marquee-anim">
                    <RunningText text={text_run} />
                </div>
            </div>
        </div>
    </>
}

export default HeaderPrev
